$color-blue: #7d9aa0;
$color-white: #ebe2dd;
$color-brown: #cbbeb5;
$color-grey: #bbbbbb;
$color-green: #a7c3b4;

.content-container {
    width: 100vw;
    margin: 25px 0px;
    display: flex;
    justify-content: center;
    align-content: center;

    .content {
        width: 85vw;
        max-width: 750px;
        padding: 15px 15px;
        max-height: 86vh;
        overflow-y: scroll;
        border-radius: 10px;
        box-shadow: 0 1px 6px -2px #525252;

        &::-webkit-scrollbar {
            display: none; } } }



