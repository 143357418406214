$color-blue: #7d9aa0;
$color-white: #ebe2dd;
$color-brown: #cbbeb5;
$color-grey: #bbbbbb;
$color-green: #a7c3b4;

.header-container {
    width: 100%;
    min-height: 50px;
    background-color: $color-blue;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        width: 100%;
        height: 50px;
        max-width: 750px;

        .block {
            width: 50%;
            height: 50px;
            max-width: 375px;
            float: left;

            a {
                text-decoration: none;
                color: black; }

            .text-left {
                height: 100%;
                background-color: $color-blue;
                float: left;
                padding: 0px 5px;
                line-height: 45px;
                cursor: pointer;

                &:hover {
                    background-color: $color-green; } }

            .text-right {
                float: right;
                margin-right: 5px;
                line-height: 45px; } } } }




