$color-blue: #7d9aa0;
$color-white: #ebe2dd;
$color-brown: #cbbeb5;
$color-grey: #bbbbbb;
$color-green: #a7c3b4;

.login-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.box {
  @media only screen and (max-width: 700px) {
    width: 285px; } }


