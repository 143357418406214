body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-container {
  width: 100%;
  min-height: 50px;
  background-color: #7d9aa0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .header-container .content {
    width: 100%;
    height: 50px;
    max-width: 750px; }
    .header-container .content .block {
      width: 50%;
      height: 50px;
      max-width: 375px;
      float: left; }
      .header-container .content .block a {
        text-decoration: none;
        color: black; }
      .header-container .content .block .text-left {
        height: 100%;
        background-color: #7d9aa0;
        float: left;
        padding: 0px 5px;
        line-height: 45px;
        cursor: pointer; }
        .header-container .content .block .text-left:hover {
          background-color: #a7c3b4; }
      .header-container .content .block .text-right {
        float: right;
        margin-right: 5px;
        line-height: 45px; }

.container {
  width: 100vw;
  height: 100vh; }

.content-container {
  width: 100vw;
  margin: 25px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center; }
  .content-container .content {
    width: 85vw;
    max-width: 750px;
    padding: 15px 15px;
    max-height: 86vh;
    overflow-y: scroll;
    border-radius: 10px;
    box-shadow: 0 1px 6px -2px #525252; }
    .content-container .content::-webkit-scrollbar {
      display: none; }

.login-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }

.button {
  width: 275px;
  padding: 12px 20px;
  background-color: #7d9aa0;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center; }
  .button:hover {
    background-color: #a7c3b4; }

.valid {
  background-color: #CEF6C3; }

.not-valid {
  background-color: #F6C3C3; }

.text-box-big {
  width: 275px;
  padding: 12px 20px;
  margin: 8px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; }

.text-box-medium {
  width: 175px;
  padding: 12px 20px;
  margin: 8px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; }
  @media only screen and (max-width: 700px) {
    .text-box-medium {
      width: 275px; } }

.text-box-small {
  width: 100px;
  padding: 12px 20px;
  margin: 8px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; }
  @media only screen and (max-width: 700px) {
    .text-box-small {
      width: 275px; } }

.login-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }

@media only screen and (max-width: 700px) {
  .box {
    width: 285px; } }

