$color-blue: #7d9aa0;
$color-white: #ebe2dd;
$color-brown: #cbbeb5;
$color-grey: #bbbbbb;
$color-green: #a7c3b4;

.button {
  width: 275px;
  padding: 12px 20px;
  background-color: $color-blue;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  &:hover {
    background-color: $color-green; } }

.valid {
  background-color: #CEF6C3; }

.not-valid {
  background-color: #F6C3C3; }


.text-box-big {
  width: 275px;
  padding: 12px 20px;
  margin: 8px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; }

.text-box-medium {
  width: 175px;
  padding: 12px 20px;
  margin: 8px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  @media only screen and (max-width: 700px) {
    width: 275px; } }

.text-box-small {
  width: 100px;
  padding: 12px 20px;
  margin: 8px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  @media only screen and (max-width: 700px) {
    width: 275px; } }
